<template>
  <c-box
    margin-top="24px"
  >
    <MealPlanMenuRecommendationContainer
      :is-edited="isEdited"
      :is-loading-data="isLoadingData"
      :preview="preview"
      :calendars="calendars"
      :is-program-consultation-basic="isProgramConsultationBasic"
    />
  </c-box>
</template>

<script>
import { 
  reqAdmin_mealPlans_menuRecommendation_calendar,
  reqAdmin_mealPlans_menuRecommendation_preview,
} from '@/requests/dietela-api/admin/meal-plan'
import MealPlanMenuRecommendationContainer from '@/components/meal-plan/menu-recommendation/container.vue'

export default {
  components: {
    MealPlanMenuRecommendationContainer,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingData: true,
      preview: null,
      calendars: [],
    }
  },
  computed: {
    day() {
      return this.$route.query.day ?? 1
    },
    month() {
      return this.$route.params.month ?? 1
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.init()
      },
    },
  },
  methods: {
    async init() {
      this.isLoadingData = true
      if (this.calendars.length === 0) {
        const res = await reqAdmin_mealPlans_menuRecommendation_calendar(this.$store.getters.axios,
          {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
          },
        )
        this.calendars = res?.data?.data
      }
      
      const selectedDays = this.calendars.find((it) => it?.days == this.day && it?.month == this.month)
      if (selectedDays && selectedDays?.id) {
        const preview = await reqAdmin_mealPlans_menuRecommendation_preview(this.$store.getters.axios, {
          id: selectedDays?.id,
        })
        this.preview = (preview?.data?.data && Object.keys(preview?.data?.data).length > 0) ? preview?.data?.data : null
      } else {
        this.preview = null
      }

      this.isLoadingData = false
    },
  },
}
</script>
