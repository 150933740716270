var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('MealPlanMenuRecommendationContainer', {
    attrs: {
      "is-edited": _vm.isEdited,
      "is-loading-data": _vm.isLoadingData,
      "preview": _vm.preview,
      "calendars": _vm.calendars,
      "is-program-consultation-basic": _vm.isProgramConsultationBasic
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }